export const menuItems = [
  { href: '/', label: 'Home' },
  { href: '/pricing', label: 'Pricing' },
  { href: '/videosection', label: 'Video Tutorial' },
  { href: '/contact', label: 'Contact Us' },
];

export const buttons = [
  { label: 'Sign In', bgColor: 'bg-red-500', hoverColor: 'hover:bg-red-600', roundedClass: 'rounded-l-lg' },
  { label: 'Sign Up', bgColor: 'bg-red-500', hoverColor: 'hover:bg-red-600', roundedClass: 'rounded-r-lg' },
];

export const bannerContent = {
  posterSrc: 'video/banner-video3.mp4',
  title: 'Welcome To SalesMagnet',
  subtitle: 'The Create prospect lists from LinkedIn',
  description: 'We help thousands of LinkedIn users simplify prospecting efforts We help thousands of LinkedIn users simplify prospecting efforts, grow their business with our advanced LinkedIn automation software.',
  buttonText: 'Start Free Trial',
  buttonLink: '#free-trial',
};

export const footerInfo = {
  logo: 'images/logo/logow.svg',
  description: 'The ultimate marketing automation solution for business owners, marketers, and recruiters.',
};

export const productMenu = [
  { label: 'Features', href: '#' },
  { label: 'Video Tutorial', href: '#' },
  { label: 'Pricing', href: '#' },
  { label: 'Profile Makeover', href: '#' },
  { label: 'LinkedIn Analytics', href: '#' },
  { label: 'Sales Solutions for LinkedIn', href: '#' },
  { label: 'LinkedIn Prospecting Tools', href: '#' },
  { label: 'LinkedIn Auto Endorse', href: '#' },
];

export const companyMenu = [
  { label: 'Home', href: '/Home.hs' },
  { label: 'Pricing', href: 'pricing.html' },
  { label: 'Video Tutorial', href: 'video-tutorial.html' },
  { label: 'Contact Us', href: 'contactus.html' },
];  

export const aboutContent = {
  heading: {
    subheading: "About SalesMagnet",
    title: "Empowering businesses with innovative LinkedIn automation solutions.",
  },
  paragraphs: [
    "SalesMagnet offers a seamless LinkedIn automation experience, helping you supercharge your lead generation and networking. With our easy-to-use platform, you can save time and grow your business effortlessly.",
    "Our tools enable you to automate repetitive tasks, allowing you to focus on building valuable connections and closing deals."
  ],
};

export const featuresContent = {
  heading: {
      subheading: "Key Features",
      title: "Boost Your LinkedIn Outreach with SalesMagnet",
  },
  description: "SalesMagnet provides powerful automation features designed to streamline your LinkedIn prospecting and increase efficiency.",
  featuresList: [
      "Automated (personalized) connection requests on LinkedIn",
      "Bulk messaging to 1st-degree connections",
      "Automatic skill endorsements on LinkedIn profiles",
      "Profile visits and engagement automation",
      "Comprehensive LinkedIn analytics to track your performance",
      "Advanced integration with LinkedIn Sales Navigator and Recruiter Lite",
  ],
};

export const ourServicesContent = {
  heading: {
    subheading: "Our Services",
    title: "SalesMagnet’s Automated Solutions",
    description: "Maximize your LinkedIn success with our powerful suite of tools.",
  },
  servicesList: [
    {
      title: "Connect via Email",
      iconClass: "fa-solid fa-envelope color00517C text-5xl max-[767px]:text-4xl",
      bgColor: "bgFFF2FB",
      description:
        "Overcome LinkedIn's weekly invite limits by sending connection requests via email, without compromising your account’s safety.",
    },
    {
      title: "Data Import/Export",
      iconClass: "fa-solid fa-file color3B88F5 text-5xl max-[767px]:text-4xl",
      bgColor: "bgF7F7F7",
      description:
        "Upload and manage LinkedIn profiles in SalesMagnet for automation, or export prospect data to a CSV file.",
    },
    {
      title: "LinkedIn Compatibility",
      iconClass: "fa-brands fa-linkedin-in text-5xl max-[767px]:text-4xl colorC54942",
      bgColor: "bgFEECEE",
      description:
        "SalesMagnet works seamlessly with LinkedIn Free, Premium, Sales Navigator, and Recruiter Lite accounts.",
    },
  ],
};

export const Data = [
  {
    id: 1,
    title: "Contact Us",
    desc: "Our dedicated support team is here to help with any questions or issues you might have:",
  },
  {
      id: 2,
      title: "FAQs",
      desc: "Common questions about LinkedIn automation with SalesMagnet.",
  },
  {
      id: 3,
      title: "Pricing Plans",
      desc: "Explore our affordable pricing plans and get started with SalesMagnet's advanced LinkedIn automation.",
  },
];

export const copyrightText = '© 2018-2024 SalesMagnet. All rights reserved.';
