import { createSlice } from '@reduxjs/toolkit';

// Initial State
export const INITIAL_STATE = {
    plans: [],  // Should be an array to match your component logic
  loading: false,
  error: null,
  response: null,
  getAllSubscription: null

};

const PricingPlansSlice = createSlice({
  name: 'PricingPlans',
  initialState: INITIAL_STATE,
  reducers: {
    PricingPlansRequest(state) {
      state.loading = true;
      state.error = null;
      state.response = null;
    },
    PricingPlansSuccess(state, { payload }) {
      state.loading = false;
      state.response = payload;
      state.plans = payload;  
    },
    PricingPlansFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;   
    },
    CreateSubscriptionRequest(state) {
      state.loading = true;
      state.response = null;
      state.error = null;
    },
    CreateSubscriptionSuccess(state, { payload }) {
      state.loading = false;
      state.response = payload;
      state.plans.push(payload.plan);  
    },
    CreateSubscriptionFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;   
    },
    getAllSubscriptionRequest(state) {
      state.loading = true;
      state.error = null;
      state.response = null;
    },
    getAllSubscriptionSuccess(state, { payload }) {
      state.loading = false;
      state.getAllSubscription = payload;  // Store subscription data here
    },
    getAllSubscriptionFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;   
    },
    cancelSubscriptionRequest(state) {
      state.loading = true;
      state.response = null;
      state.error = null;
    },
    cancelSubscriptionSuccess(state, { payload }) {
      state.loading = false;
      state.response = payload;
      state.plans.push(payload.plan);  
    },
    cancelSubscriptionFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;   
    },
  },
});

export const {
  PricingPlansRequest,
  PricingPlansSuccess,
  PricingPlansFailure,
  CreateSubscriptionRequest,
  CreateSubscriptionSuccess,
  CreateSubscriptionFailure,
  getAllSubscriptionRequest,
  getAllSubscriptionSuccess,
  getAllSubscriptionFailure,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure
} = PricingPlansSlice.actions;

export default PricingPlansSlice.reducer;
