import SignIn from "../components/signIn/signIn";
import SignUp from "../components/SignUpForm/SignUp";
import ForgotPassword from "../components/Forgot-Password/forgot";
import Dashboard from "../components/Dashboard/Dashboard";
import PersonalDetails from "../components/Personal-Details/personalDetails";
import PaymentSuccessCard from "../components/Pricing-Plans/PaymentSuccessCard ";
import PaymentFailureCard from "../components/Pricing-Plans/PaymentFailureCard";

const commonRoutes = [
  {
    path: "/signin",
    component: SignIn,
  },
  {
    path: "/signup",
    component: SignUp,
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/settings",
    component: PersonalDetails,
  },
  {
    path: "/payment/success",
    component: PaymentSuccessCard,
  },
  {
    path: "/payment/failure",
    component: PaymentFailureCard,
  },
];

export { commonRoutes };
