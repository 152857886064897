import React from "react";

const PaymentFailureCard = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="p-6 rounded-lg bg-red-100 text-red-900 text-center shadow-lg max-w-sm">
        <h1 className="text-lg font-bold mb-4 !text-[26px]">Payment Failed ❌</h1>
        <p className="text-base mb-4">
          Unfortunately, your payment could not be processed. Please try again or contact support.
        </p>
        <button
          className="px-4 py-2 rounded bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400"
          onClick={() => window.location.href = "/"}
        >
          Retry Payment
        </button>
      </div>
    </div>
  );
};

export default PaymentFailureCard;
