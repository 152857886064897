
// PricingPlansApi.js
import { apiRequest } from "./apiHelper";

// Use the endpoint directly, no need for BASE_URL in this file
export const PricingPlansApi = async () => {
  return await apiRequest("/api/plans"); // Just pass the endpoint
};

export const CreateSubscriptionApi = async (plan_id) => {
  return await apiRequest("/api/subscription/create", "POST", { plan_id });
};

export const getAllSubscriptionApi = async () => {
  return await apiRequest("/api/subscription");
};

export const cancelSubscriptionApi = async () => {
  return await apiRequest("/api/subscription/cancel", "DELETE");
};

export const updateSubscriptionApi = async (newPriceId) => {
  return await apiRequest("/api/subscription/update", "POST", { newPriceId });
};
