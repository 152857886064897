import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import for navigation
import { decryptUserData } from "../../utils/encryption";
import {
  getAllSubscriptionApi,
  cancelSubscriptionApi,
} from "../../api/PricingPlansApi";
import { userShowAlert } from "../../customHooks/userShowAlert"

function PersonalDetails() {
  const [activeTab, setActiveTab] = useState("profile");
  const [subscriptionData, setSubscriptionData] = useState(null);
  const navigate = useNavigate(); // For navigation

  const user = decryptUserData();
  if (user) {
    console.log("Decrypted User Data:", user);
  } else {
    console.log("No user data found or decryption failed.");
  }

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await getAllSubscriptionApi();
        console.log("API Response:", response);

        if (response && response.data) {
          setSubscriptionData(response.data);
        } else {
          console.log("No subscription data found");
        }
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    };

    fetchSubscriptionData();
  }, []);

  const handleCancel = async () => {
    if (!subscriptionData) {
      await userShowAlert('Cancelled', 'No subscription to cancel.', 'warning', 'OK');
    } else {
      try {
        const result = await userShowAlert(
          'Are you sure?',
          'You wan\'t to cancel the subscription!',
          'warning',
          'Yes, Cancel it!',
          'No!'
        );
        if (result.isConfirmed) {
          const response = await cancelSubscriptionApi(subscriptionData.id); // Pass the subscription ID
          if (response && response.statusCode === 200) {
            await userShowAlert('Success', `${response.message}`, 'success', 'OK');
          }
          setSubscriptionData(null); // Reset subscription data
        } else {
          await userShowAlert('Cancelled', `Your subscription is safe`, 'error', 'OK');
        }
      } catch (error) {
        console.error("Error cancelling subscription:", error);
      }
    }
  };

  const profileContent = (
    <div>
      <h2 className="text-sm font-bold mb-8">Profile</h2>
      <div className="flex flex-col gap-1">
        <p>Name: {user ? user.name : "User"}</p>
        <p>Email: {user ? user.email : "User"}</p>
      </div>
    </div>
  );

  const billingContent = (
    <div>
      <h2 className="text-sm font-bold mb-8">Billing Manager</h2>
      {subscriptionData ? (
        <div className="flex flex-col gap-1">
          <p>
            <strong>Activate Plans:</strong>{" "}
            {subscriptionData?.plan.metadata.plan_name || "N/A"}
          </p>
          <p>
            <strong>{subscriptionData?.status === "trialing" ? "Trialing Start Date:" : "Plan Start Date:"}</strong>{" "}
            {new Date(
              subscriptionData.current_period_start * 1000
            ).toLocaleDateString("en-GB")}
          </p>

          <p>
            <strong>{subscriptionData?.status === "trialing" ? "Trialing End Date:" : "Plan End Date:"}</strong>{" "}
            {new Date(
              subscriptionData.current_period_end * 1000
            ).toLocaleDateString("en-GB")}
          </p>
          {
            <p className="text-black-500 font-medium">
              {
                subscriptionData.status === "trialing" ? "Your subscription is currently in the trial period." : subscriptionData?.schedule ? `Your plan has been successfully downgraded from the ${subscriptionData?.plan.metadata.plan_name.split("$")[0].trim()} to the ${subscriptionData?.schedule?.upcoming_plan_name.split("$")[0].trim()}.` : ""
              }
            </p>
          }
          {/* {subscriptionData.schedule?.upcoming_plan_name && (
          <p className="mt-4 text-blue-600 font-semibold">
            Your upcoming plan,{" "}
            <span className="font-bold">
              {subscriptionData.schedule.upcoming_plan_name}
            </span>, 
            will start on{" "}
            {new Date(
              subscriptionData.schedule.upcoming_plan_start_date * 1000
            ).toLocaleDateString("en-GB")}.
          </p>
        )} */}

          <div className="flex space-x-4 mt-4">
            <button
              className="bg-green-500  transition rounded-full text-white bgFF3E3A borderFF3E3A border py-1.5 px-8 font-light inline-block max-[1024px]:px-6 max-[1024px]:py-1 max-[650px]:px-6 max-[650px]:py-1.5"
              onClick={() => navigate("/pricing")}
            >
              Upgrade/Downgrade
            </button>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded-full transition bgFF3E3A borderFF3E3A border  font-light inline-block max-[1024px]:px-6 max-[1024px]:py-1 max-[650px]:px-6 max-[650px]:py-1.5"
              onClick={handleCancel}
            >
              Cancel Subscription
            </button>
          </div>
        </div>
      ) : (
        <div className="">
          <p>No subscription data available.</p>
          <button
            className="bg-blue-500 text-white px-4 py-2 mt-4 rounded"
            onClick={() => navigate("/pricing")}
          >
            Go to Pricing Plans
          </button>
        </div>
      )}
    </div>
  );

  return (
    <section className={`pricing-section flex`}>
      {/* Tabs for Profile and Billing Manager */}
      <div className="container-fluid mx-auto relative flex gap-14 flex-col max-[900px]:gap-5 w-full">
        <div className="flex mt-6 gap-5 max-[1024px]:mt-5 max-[950px]:flex-wrap">
          <div className="w-full">
            <div className="relative">
              <ul className="relative font-semibold flex flex-wrap gap-1">
                {["profile", "billing"].map((tab) => (
                  <li key={tab} className="z-30 pricing-list">
                    <button
                      className={`cursor-pointer font-semibold text-base pt-2 pb-2 ps-5 inline-block rounded-t-2xl pe-5 ${activeTab === tab ? "bg-[#f2f3fa]" : ""
                        }`}
                      role="tab"
                      aria-selected={activeTab === tab}
                      onClick={() => setActiveTab(tab)}
                    >
                      <span>
                        {tab === "profile" ? "Profile" : "Billing Manager"}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
              <div className="p-8 max-[650px]:p-4 bg-[#f2f3fa] rounded-2xl rounded-tl-none pricing-b-cover">
                <div className="w-full p-10 pt-12 bg-white rounded-2xl relative max-[950px]:p-4 max-[950px]:w-6/12 PersonalDetails-card">
                  <span class="bg-[#fbe0f3] flex h-2 rounded-full absolute top-0 w-10/12 left-1/2 -translate-x-1/2 -translate-y-1/2"></span>
                  {activeTab === "profile" && profileContent}
                  {activeTab === "billing" && billingContent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content based on active tab */}
    </section>
  );
}

export default PersonalDetails;
