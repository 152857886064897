import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginUserApi } from "../../api/signupApi";
import {
  loginSuccess,
  loginFailure,
} from "../../redux/reducers/ducks/signupDuck";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { encryptUserData, decryptUserData } from "../../utils/encryption"; // Use ES6 import

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const user = decryptUserData();
    if (user) {
      console.log("User Data:", user);
    } else {
      console.log("No valid user data found.");
    }
  }, []);

  const checkEmail = (email) => {
    const filter = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return filter.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please fill in both fields.");
      return;
    }

    if (!checkEmail(email)) {
      toast.error("Please provide a valid email address");
      return;
    }

    try {
      const response = await loginUserApi({ email, password });
      if (response?.status === "success" && response?.statusCode === 200) {
        dispatch(loginSuccess(response.data));
        Cookies.set("token", response.data.token, { expires: 1 });
        encryptUserData(response.data);

        toast.success("Login successful!");
        navigate("/");
      } else {
        const errorMessage =
          response?.statusCode === 401
            ? response.message || "Invalid email or password."
            : response.message || "Login failed. Please try again.";
        toast.error(errorMessage);
        dispatch(loginFailure());
      }
    } catch (error) {
      dispatch(loginFailure());
      toast.error("An error occurred. Please try again.");
      console.error("API call error:", error.message || error);
    }
  };

  const handleLogout = () => {
    Cookies.remove("token"); // Remove token from cookies
    localStorage.removeItem("userData"); // Remove user data from localStorage
    dispatch(loginFailure()); // Clear any redux state if needed
    navigate("/signin"); // Redirect to sign-in page
  };

  const isLoggedIn = Cookies.get("token") && localStorage.getItem("userData");

  return (
    <section className="contactus-section pt-0 pb-0 back">
      <div className="container-fluid mx-auto relative flex gap-5 max-[950px]:flex-col justify-center h-screen items-center">
        <div className="flex mt-6 max-[650px]:mt-3 gap-5 max-[1024px]:mt-5 max-[950px]:flex-wrap w-6/12 max-[950px]:w-full bg-white rounded-2xl p-10 max-[1024px]:p-8 max-[767px]:p-6">
          <div className="contactform w-full">
            <div className="page-title mb-7 max-[1024px]:mb-3">
              <h2 className="font-bold color00517C pb-3">Sign In</h2>
              <span className="title-border mb-3 w-28 h-px bgFF3E3A block"></span>
              <div className="ptag font-normal color5a5783 pb-2">
                Meet our team of professional support agents ready to take care
                of any issue you might face:
              </div>
            </div>

            {/* If logged in, show logout button */}
            {isLoggedIn ? (
              <div>
                <h3>Welcome back! You are already logged in.</h3>
                <button
                  onClick={handleLogout}
                  className="transition rounded-full w-full text-white bgFF3E3A borderFF3E3A border py-1.5 px-8 font-light inline-block max-[1024px]:px-6 max-[1024px]:py-1 max-[650px]:px-6 max-[650px]:py-1.5"
                >
                  Logout
                </button>
              </div>
            ) : (
              <form
                className="flex flex-col gap-4 max-[650px]:gap-3"
                onSubmit={handleSubmit}
              >
                <div className="contactform-row flex gap-5 max-[650px]:gap-3 justify-between w-full max-[650px]:flex-col">
                  <div className="inputgroup contact-input w-full">
                    <label className="color00517C text-base max-[650px]:text-sm font-semibold">
                      E-mail Address{" "}
                      <span className="text-red-500 text-xl">*</span>
                    </label>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full border rounded-2xl h-11 outline-0 px-5 py-2 color00517C font-normal text-base max-[650px]:h-10"
                      placeholder="E-mail Address"
                    />
                  </div>
                </div>
                <div className="contactform-row flex gap-5 max-[650px]:gap-3 justify-between w-full max-[650px]:flex-col">
                  <div className="inputgroup contact-input w-full">
                    <label className="color00517C text-base max-[650px]:text-sm font-semibold">
                      Password <span className="text-red-500 text-xl">*</span>
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full border rounded-2xl h-11 outline-0 px-5 py-2 color00517C font-normal text-base max-[650px]:h-10"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div className="contactform-row flex gap-5 max-[650px]:gap-3 justify-between w-full ">
                  <div className="inputgroup contact-input w-6/12 max-[650px]:w-full">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      name="rememberMe"
                      value="Remember Me"
                    />
                    <label
                      htmlFor="rememberMe"
                      className="color00517C text-base ml-2 max-[650px]:text-sm font-semibold"
                    >
                      Remember Me
                    </label>
                  </div>
                  <div className="inputgroup contact-input w-6/12 max-[650px]:w-full text-end">
                    <Link
                      to="/forgotpassword"
                      className="color00517C text-base max-[650px]:text-sm font-semibold"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                <div className="contactform-row flex gap-5 justify-between w-full mt-2">
                  <div className="inputgroup contact-btn b-btn w-full">
                    <button
                      type="submit"
                      className="transition rounded-full w-full text-white bgFF3E3A borderFF3E3A border py-1.5 px-8 font-light inline-block max-[1024px]:px-6 max-[1024px]:py-1 max-[650px]:px-6 max-[650px]:py-1.5"
                    >
                      Sign In
                    </button>
                  </div>
                </div>
                <div className="formdec mt-5 max-[1024px]:mt-2">
                  <div className="font-normal color5a5783 text-base max-[650px]:text-sm">
                    New Account?{" "}
                    <Link
                      to="/signup"
                      className="color00517C text-base max-[650px]:text-sm font-semibold"
                    >
                      Sign Up
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
