import { combineReducers } from '@reduxjs/toolkit';
import certificateReducer from './ducks/CertificateDuck';
 import signUpSlice from './ducks/signupDuck'
import PricingPlansSlice from './ducks/PricingPlansDuck'

const rootReducer = combineReducers({
  certificate: certificateReducer,
  signup: signUpSlice,
  PricingPlans: PricingPlansSlice
 });

export default rootReducer;