import React from "react";

const PaymentSuccessCard = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="p-6 rounded-lg bg-green-100 text-green-900 text-center shadow-lg max-w-sm">
        <h1 className="text-green-900 !text-[26px]">
          Payment Successful 🎉
        </h1>
        <p className="text-base mb-6">
          Thank you for your payment. Your transaction has been successfully
          processed.
        </p>
        <button
          className="px-4 py-2 rounded bg-green-600 text-white hover:bg-green-700 transition-colors"
          onClick={() => (window.location.href = "/")}
        >
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessCard;
