import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.svg';
import userimg from '../../assets/img/user.svg';
import { MAIN_SIDEBAR_DATA } from '../Sidebar/SidebarData';
import { decryptUserData } from '../../utils/encryption';  // Import the decrypt function

function Sidebar({ onSelect }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

   const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

   const user = decryptUserData();
    if (user) {
    console.log('Decrypted User Data:', user);
  } else {
    console.log('No user data found or decryption failed.');
  }
  return (
    <div className="relative">

      <div
        className={`w-72 max-[1400px]:w-64 left-baar-menu h-screen bg-white fixed left-0 top-0 border transition-all duration-300 z-20  max-[1150px]:-translate-x-64
          ${isSidebarOpen ? 'l-menu-o' : ''}
          `}
      >
        <div className="menu-baar-d hidden absolute top-2 -right-12 z-30 max-[1150px]:flex">
          <button
            onClick={toggleSidebar}
            className={`toggle relative w-11 h-10 max-[1023px]:h-8 bgFF3E3A rounded-xl flex items-center justify-center cursor-pointer overflow-hidden ${isSidebarOpen ? 'active' : ''}`}
          >
            <span
              className={`bg-white absolute transition w-5 h-0.5 left-1.5 rounded-full ${isSidebarOpen ? "rotate-45 translate-y-2" : "translate-y-0"
                }`}
            ></span>
            <span
              className={`bg-white absolute transition  w-5 h-0.5 left-1.5 rounded-full`}
            ></span>
            <span
              className={`bg-white absolute transition   w-8 h-0.5 left-1.5 rounded-full ${isSidebarOpen ? "-rotate-45 -translate-y-2" : "translate-y-0"
                }`}
            ></span>
          </button>
        </div>
        <div className="logo flex justify-center items-center w-full h-14 border-b">
          <Link to="/">
            <img src={logo} className="transition w-46" alt="Logo" />
          </Link>
        </div>

        <div className="user-profile text-center flex flex-col justify-center items-center pt-8 block pb-20">
          <div className="user-profile-img w-24 h-24 overflow-hidden rounded-full outline outline-offset-2 outline-1 outline-cyan-500">
            <img src={userimg} className="w-full" alt="User" />
          </div>
          <h4 className="color00517C font-semibold pt-3 pb-1">{user ? user.name : 'User'}</h4>
        </div>

        {/* Sidebar Menu */}
        <div className="left-menu">
          <ul className="flex flex-col">
            {MAIN_SIDEBAR_DATA.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.link || '#'}
                  onClick={() => onSelect(item.heading)}
                  className="flex items-center font-light color5a5783 gap-3 transition px-5 py-3 relative"
                >
                  <span className="icon w-6 h-6">
                    <img src={item.icon} alt={item.heading} className="w-full h-full" />
                  </span>
                  <span className="text-xl font-normal">{item.heading}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={`right-baar-div ${isSidebarOpen ? 'transition' : 'bluractive'}`}>
      </div>
    </div>
  );
}

export default Sidebar;

