import React, { useState } from 'react';
import Slider from 'react-slick'; 
import a1 from '../../assets/images/a1.svg';
import a2 from '../../assets/images/a2.svg';
import a3 from '../../assets/images/a3.svg';
import a4 from '../../assets/images/a4.svg';
import ContactForm from './ContactForm'; 
import {Data} from '../../utils/Data'
import { useLocation } from "react-router-dom";

const contactInfo = {
  buttonLabel: "Submit",
  logos: [a1, a2, a3, a4]
};

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: ''
    });
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    arrows:false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const location = useLocation(); 

  const noAbsolutePaths = ["/pricing", "/contact", "/videosection"];
 const isNoAbsolutePath = noAbsolutePaths.includes(location.pathname);
  return (
    <section
      className={`contactus-section pt-0 ${isNoAbsolutePath ? "pt-10" : "pt-0"} flex`}
    > 
       <div className="container-fluid mx-auto relative flex gap-5 max-[950px]:flex-col">
        <div className="w-4/12 text-left max-[950px]:w-full">
          <h4 className="colorFF3E3A mb-2 relative max-[767px]:mb-1">{Data[0].title}</h4>
          <h2 className="font-bold color00517C pb-3">{Data[0].title}</h2>
          <span className="title-border mb-3 w-28 h-px bgFF3E3A block"></span>
          <div className="ptag font-normal color5a5783 pb-2">{Data[0].desc}</div>
          <div className="c-logo pt-12 overflow-clip">
            <Slider {...sliderSettings}>
              {contactInfo.logos.map((logo, index) => (
                <div key={index} className="slick-slide">
                  <img src={logo} alt={`Logo ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="flex mt-6 max-[650px]:mt-3 gap-5 max-[1024px]:mt-5 max-[950px]:flex-wrap w-8/12 max-[950px]:w-full">
          <div className="contactform w-full">
            <ContactForm 
              formData={formData} 
              handleChange={handleChange} 
              handleSubmit={handleSubmit} 
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
