import { put, call, takeLatest } from "redux-saga/effects";
import {
  PricingPlansRequest,
  PricingPlansSuccess,
  PricingPlansFailure,
  CreateSubscriptionRequest,
  CreateSubscriptionSuccess,
  CreateSubscriptionFailure,
  getAllSubscriptionRequest,
  getAllSubscriptionSuccess,
  getAllSubscriptionFailure,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure
} from "../../reducers/ducks/PricingPlansDuck";
import {
  PricingPlansApi,
  CreateSubscriptionApi,
  getAllSubscriptionApi,
  cancelSubscriptionApi
} from "../../../api/PricingPlansApi";

function* PricingPlansSaga(action) {
  try {
    const response = yield call(PricingPlansApi, action.payload);
    yield put(PricingPlansSuccess(response));
  } catch (error) {
    const errorMsg = error.message || "An unexpected error occurred";
    yield put(PricingPlansFailure(errorMsg));
  }
}

function* CreateSubscriptionSaga(action) {
  try {
    const response = yield call(CreateSubscriptionApi, action.payload);
    console.log("Login API response:", response);
    yield put(CreateSubscriptionSuccess(response));
  } catch (error) {
    const errorMsg = error.message || "An unexpected error occurred";
    yield put(CreateSubscriptionFailure(errorMsg));
  }
}

function* getAllSubscriptionSaga(action) {
  try {
    const response = yield call(getAllSubscriptionApi, action.payload);    
    yield put(getAllSubscriptionSuccess(response));
  } catch (error) {
    const errorMsg = error.message || "An unexpected error occurred";
    yield put(getAllSubscriptionFailure(errorMsg));
  }
}

function* cancelSubscriptionSaga(action) {
  try {
    const response = yield call(cancelSubscriptionApi, action.payload);
    yield put(cancelSubscriptionSuccess(response));
  } catch (error) {
    const errorMsg = error.message || "An unexpected error occurred";
    yield put(cancelSubscriptionFailure(errorMsg));
  }
}


export function* watchPricingPlansSagas() {
  yield takeLatest(PricingPlansRequest.type, PricingPlansSaga);
}

export function* watchCreateSubscriptionSagas() {
  yield takeLatest(CreateSubscriptionRequest.type, CreateSubscriptionSaga);
}

export function* watchgetAllSubscriptionSagas() {
  yield takeLatest(getAllSubscriptionRequest.type, getAllSubscriptionSaga);
}

export function* watchcancelSubscriptionSagas() {
  yield takeLatest(cancelSubscriptionRequest.type, cancelSubscriptionSaga);
}
