// apiHelper.js
import Cookies from "js-cookie";
import { BASE_URL } from "./axios";

// Common fetch function
export const apiRequest = async (endpoint, method = "GET", body = null, customHeaders = {}) => {
    try {
        const token = Cookies.get("token"); // Get token from cookies

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token ? `Bearer ${token}` : undefined, // Include Authorization header if token exists
            ...customHeaders, // Merge any additional headers
        };

        // Construct the full URL using BASE_URL and the endpoint
        const url = `${BASE_URL}${endpoint}`;

        const response = await fetch(url, {
            method,
            headers,
            credentials: "include", // Include credentials for cookies
            body: body ? JSON.stringify(body) : null, // Add body only if it's not null
        });

        if (!response.ok) {
            const errorDetails = await response.json();
            throw new Error(
                `Error ${response.status}: ${errorDetails.message || response.statusText}`
            );
        }

        return await response.json(); // Return the parsed JSON response
    } catch (error) {
        console.error("API call error:", error.message || error);
        throw error;
    }
};
