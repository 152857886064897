import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { CreateSubscriptionApi, updateSubscriptionApi } from "../../api/PricingPlansApi";
 import { useSelector } from "react-redux";
import { userShowAlert } from "../../customHooks/userShowAlert"
import { getAllSubscriptionRequest } from "../../redux/reducers/ducks/PricingPlansDuck"; // Assuming these are the correct paths
import { useDispatch } from "react-redux";
function PricingPlanCards({ plan }) {
  const [plans, setPlans] = useState({});
  const [loading] = useState(false);
  const dispatch = useDispatch();

  // Access response from Redux store
  const { getAllSubscription } = useSelector(({PricingPlans}) => ({
    getAllSubscription: PricingPlans?.getAllSubscription
  }));
  // Extracting plan active status
console.log(getAllSubscription , 'getAllSubscriptiongetAllSubscription');
 
  useEffect(() => {
    const featuresData = plan.metadata.features;

    if (featuresData) {
      let cleanFeaturesString = featuresData
        .replace(/\\n/g, " ")
        .replace(/\\"/g, '"')
        .replace(/\\(?!")/g, "")
        .replace(/^"|"$/g, "")
        .trim();

      cleanFeaturesString = cleanFeaturesString.replace(/,\s*$/, "");
      cleanFeaturesString = cleanFeaturesString.replace(/"$/, "");

      if (
        cleanFeaturesString.startsWith("{") &&
        cleanFeaturesString.endsWith("}")
      ) {
        try { 
          const parsedFeatures = JSON.parse(cleanFeaturesString);
          setPlans(parsedFeatures);
        } catch (e) {
          console.error("Error parsing features:", e);
          setPlans({ defaultFeature: `Error parsing features: ${e.message}` });
        }
      } else {
        console.error("Invalid JSON format:", cleanFeaturesString);
        setPlans({
          defaultFeature: `Invalid JSON format: ${cleanFeaturesString}`,
        });
      }
    } else {
      console.warn("No features data available");
      setPlans({ defaultFeature: "No features available" });
    }
  }, [plan.metadata.features]);

  const isplanid = getAllSubscription?.data.plan.id || false;
  const isActivePlan = getAllSubscription?.data?.plan.id === plan.id;
  // Active plan details
  const activePlanType = getAllSubscription?.data?.plan.metadata?.plan_name.split("$")[0].trim();
  // Current plan details
  const currentPlanType = plan?.metadata?.plan_name.split("$")[0].trim();
  const currentPlanInterval = plan?.recurring?.interval;

  // Determine button label based on plan type
  let buttonLabel = "Subscribe";
  if (isActivePlan) {
    buttonLabel = "Subscribed";
  } else if (!isActivePlan) {
    if (activePlanType === "DIY") {
      if (currentPlanInterval === "month" && currentPlanType === "DIY") {
        buttonLabel = "Downgrade"
      } else {
        buttonLabel = "Upgrade Plan";
      }
    } else if (activePlanType === "Agency") {
      // eslint-disable-next-line no-mixed-operators
      if (currentPlanInterval === "month" && currentPlanType === "Agency" || currentPlanType === "DIY") {
        buttonLabel = "Downgrade"
      } else {
        buttonLabel = "Upgrade Plan";
      }
    } else if (activePlanType === "Pro") {
      if (currentPlanInterval === "year" && currentPlanType === "Pro") {
        buttonLabel = "Upgrade Plan";
      } else {
        buttonLabel = "Downgrade";
      }
    }
  }

  const handleFreeTrialClick = async () => {
    try {
      // Show confirmation dialog using SweetAlert
      const result = await userShowAlert("Are you sure?", "You are about to start a free trial for this plan!", "warning", "Yes, Start Free Trial!", "No, Cancel");

      if (result.isConfirmed) {
        // Proceed with the API call
        const response = await CreateSubscriptionApi(plan.id);
        if (response?.data?.url) {
          // Show success alert before opening the URL
          await userShowAlert("Success!", "Free trial started successfully. Redirecting to the subscription page.", "success", "OK");
          window.open(response.data.url, "_blank");
        } else {
          // Show error alert if no URL is provided
          await userShowAlert("Error!", "An error occurred: No URL received from the API.", "error", "OK");
        }
      } else {
        // Handle cancelation
        await userShowAlert("Cancelled", "Your free trial request was cancelled.", "error", "OK");
      }
    } catch (err) {
      // Show error alert in case of an exception
      console.error("Error during subscription creation:", err);
      await userShowAlert("Error!", "An error occurred while creating the subscription.", "error", "OK");
    }
  };



  const handleUpgradeClick = async () => {
    const actionType = buttonLabel;
    try {
      const alertMessage = actionType === "Upgrade Plan" ? "You want to Upgrade the subscription!" : "You want to Downgrade the subscription!";
      const result = await userShowAlert("Are you sure?", alertMessage, "warning", `Yes, ${actionType} it!`, "No!");
      if (result.isConfirmed) {
        const apiResponse = await updateSubscriptionApi(plan.id)
        if (apiResponse?.statusCode === 200) {
          dispatch(getAllSubscriptionRequest());

           await userShowAlert("Success", `Thank you for confirming the ${actionType.toLowerCase()}!`, "success", "OK");
        } else {
          await userShowAlert("Cancelled", `Your ${actionType.toLowerCase()} request couldn't be processed.`, "error", "OK");
        }
      } else {
        await userShowAlert("Cancelled", `Your ${actionType.toLowerCase()} request was cancelled.`, "error", "OK");
      }
    } catch (err) {
      console.error(`Error during ${actionType.toLowerCase()}:`, err);
    }


  };


console.log(PricingPlanCards , 'PricingPlanCardsPricingPlanCardsPricingPlanCards');

  return (
    <div
      className={`w-full p-6 ${plan.colorClass} rounded-2xl relative max-[950px]:p-4 max-[950px]:w-6/12`}
    >
      <span
        className={`${plan.barColor} flex h-2 rounded-full absolute top-0 w-10/12 left-1/2 -translate-x-1/2 -translate-y-1/2`}
      ></span>
      <div
        className={`p-header ${plan.headerColor} rounded-2xl text-left p-5 max-[950px]:p-3`}
      >
        <span className="text-[#3B88F5] text-base font-semibold">
          {plan.metadata.plan_name}
        </span>
        <div className="flex items-baseline">
          <span className="text-3xl color00517C font-semibold">$</span>
          <span className="text-5xl tracking-tight font-bold max-[650px]:text-4xl">
            {plan.unit_amount / 100}
          </span>
          <span className="ms-1 text-base text-[#6b688d] font-semibold">
            {plan.metadata.trial_period_days
              ? `Trial ${plan.metadata.trial_period_days} Days`
              : "Billing Cycle"}
          </span>
        </div>
      </div>

      <ul className="space-y-2.5 my-5 max-[650px]:space-y-2 max-[650px]:my-4">
        {plans.team_members && (
          <li className="flex items-center">
            <FontAwesomeIcon icon={faCircleCheck} />
            <span className="text-base semibold leading-tight ms-3">
              {plans?.team_members}
            </span>
          </li>
        )}
        {plans.cloud_storage && (
          <li className="flex">
            <FontAwesomeIcon icon={faCircleCheck} />
            <span className="text-base semibold leading-tight ms-3">
              {plans?.cloud_storage}
            </span>
          </li>
        )}
        {plans.integration_help && (
          <li className="flex">
            <FontAwesomeIcon icon={faCircleCheck} />
            <span className="text-base semibold leading-tight ms-3">
              {plans?.integration_help}
            </span>
          </li>
        )}
        {plans.sketch_files && (
          <li className="flex ">
            <FontAwesomeIcon icon={faCircleCheck} />
            <span className="text-base semibold leading-tight ms-3">
              {plans?.sketch_files}
            </span>  
          </li>
        )}
        {plans.api_access && (
          <li className="flex ">
            <FontAwesomeIcon icon={faCircleCheck} />
            <span className="text-base semibold leading-tight ms-3">
              {plans?.api_access}
            </span>
          </li>
        )}
        {plans.complete_documentation && (
          <li className="flex ">
            <FontAwesomeIcon icon={faCircleCheck} />
            <span className="text-base semibold leading-tight ms-3">
              {plans?.complete_documentation}
            </span>
          </li>
        )}
        {plans.support && (
          <li className="flex ">
            <FontAwesomeIcon icon={faCircleCheck} />
            <span className="text-base semibold leading-tight ms-3">
              {plans?.support}
            </span>
          </li>
        )}
      </ul>

      <div className="b-btn mr-[180px]">
        <button
          onClick={
            isplanid === plan.id
              ? null // Disable click if this is the active plan
              : isplanid
                ? handleUpgradeClick
                : handleFreeTrialClick
          }
          disabled={loading || isplanid === plan.id}
          className={`whitespace-nowrap rounded-full text-white border py-1.5 px-8 font-light inline-block 
      max-[1024px]:px-6 max-[1024px]:py-1 max-[650px]:px-6 max-[650px]:py-1.5 
      ${loading
              ? "bg-gray-400 border-gray-400 cursor-not-allowed"
              : isplanid === plan.id
                ? "bg-green-500 border-green-500 cursor-default"
                : isplanid
                  ? "bg-yellow-500 border-yellow-500 hover:bg-yellow-400"
                  : "bg-[#FF3E3A] border-[#FF3E3A] hover:bg-[#FF1A17]"
            }`}
        >
          {loading ? "Processing..." : buttonLabel}
        </button>
      </div>
    </div>
  );
}

export default PricingPlanCards;

