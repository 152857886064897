  import { createSlice } from "@reduxjs/toolkit";

  export const INITIAL_STATE = {
    users: [],
    loading: false,
    error: null,
    response: null, // Add this to store the API response
  };

  const signUpSlice = createSlice({
    name: "main",
    initialState: INITIAL_STATE,
    reducers: {
      signupRequest(state) {
        state.loading = true;
        state.error = null;
        state.response = null;
      },
      signupSuccess(state, { payload }) {
        state.loading = false;
        state.response = payload; 
        state.users.push(payload.user);  
      },
      signupFailure(state, { payload }) {
        state.loading = false;
        state.error = payload;
      },

      loginRequest(state) {
        state.loading = true;
        state.error = null;
        state.response = null;
      },
      loginSuccess(state, { payload }) {
        state.loading = false;
        state.response = payload;  
        state.users.push(payload.user);  
      },
      loginFailure(state, { payload }) {
        state.loading = false;
        state.error = payload;
      },

    },
  });

  export const { signupRequest, signupSuccess, signupFailure,loginRequest,loginSuccess,loginFailure } =
    signUpSlice.actions;

  export default signUpSlice.reducer;