import { all } from 'redux-saga/effects';
 
import { watchCertificateSagas } from './sagas/CertificateSaga';
import {watchSignupSagas , watchLoginSagas} from './sagas/signupSaga'
import {watchPricingPlansSagas, watchCreateSubscriptionSagas, watchgetAllSubscriptionSagas,watchcancelSubscriptionSagas} from './sagas/PricingPlansSaga'
export default function* rootSaga() {
  yield all([
    watchCertificateSagas(),
    watchSignupSagas(),
    watchLoginSagas(),
    watchPricingPlansSagas(),
    watchCreateSubscriptionSagas(),
    watchgetAllSubscriptionSagas(),
    watchcancelSubscriptionSagas()
   ]);
}
