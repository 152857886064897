import { apiRequest } from "./apiHelper";

// Use the endpoint directly
export const registerUserApi = async (userData) => {
  return await apiRequest("/api/signup", "POST", userData);
};

export const loginUserApi = async (userData) => {
  return await apiRequest("/api/signin", "POST", userData);
};
