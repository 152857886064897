import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import icon from '../../assets/images/user.svg';
import connect from '../../assets/images/connect.svg';
import { decryptUserData } from '../../utils/encryption';

function Navbar() {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const user = decryptUserData();

  useEffect(() => {
     const token = Cookies.get('token');
    const userData = Cookies.get('user');

     if (!token || !userData) {
      navigate('/signin');
    }
  }, [navigate]);

  const handleLogout = () => {
    Cookies.remove('token');
    Cookies.remove('user');
    localStorage.removeItem('userData');
    navigate('/signin');
  };

  return (
    <>
      <div className="border-s-0 border-e-0 bordere7e9f6 border border-t-0 header-baar flex flex-col pr-7 max-[650px]:pr-3 bg-white c-padding-r fixed w-full z-10">
        <div className="flex h-14 flex-col justify-center">
          <div className="flex justify-between items-center">
            <div className="header-r-menu flex items-center gap-8 ms-auto">
              <ul className="flex gap-5 items-center">
                <li>
                  <a href="/" className="color00517C flex items-center font-light gap-1 overlay-before relative">
                    <i className="fa-solid fa-envelope"></i>
                  </a>
                </li>
                <li>
                  <a href="/" className="color00517C flex items-center font-light gap-1 overlay-before relative">
                    <i className="fa-solid fa-bell"></i>
                  </a>
                </li>
                <li className="relative" ref={dropdownRef}>
                  <button 
                    onClick={() => setShowDropdown(!showDropdown)}
                    className="color00517C flex items-center font-light gap-1 overlay-before relative"
                  >
                    <span className="icon w-7 h-7 rounded-full overflow-hidden">
                      <img src={icon} alt="img" className="w-full h-full rounded-full" />
                    </span>
                    <span className="text-base max-[1350px]:text-sm font-medium">{user ? user.name : 'User'}</span>
                    <i className="fa-solid fa-angle-down"></i>
                  </button>
                  {showDropdown && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="pr-7 c-padding-r bg-[#f2f3fa] pt-20 max-[650px]:pr-3">
        <div className="flex justify-between">
          <div className="page-title mb-3">
            <h4 className="color00517C font-semibold flex items-center gap-1 w-3.5 h-3.5 mb-2">
              <img src={connect} alt="img" className="" /> Connect
            </h4>
            <ul className="flex gap-1 items-center">
              <li>
                <i className="fa-solid fa-house"></i>
              </li>
              <li className="text-sm">/</li>
              <li>
                <a href="/" className="color00517C font-semibold text-sm max-[550px]:xs">Dashboard</a>
              </li>
              <li className="text-sm">/</li>
              <li>
                <a href="/" className="color5a5783 font-semibold text-sm max-[550px]:xs">Connect</a>
              </li>
            </ul>
          </div>
          <div className="top-heder-drop">
            <select>
              <option>Default Campaign</option>
              <option>Default Campaign1</option>
              <option>Default Campaign2</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
