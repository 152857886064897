import Swal from 'sweetalert2';

// Common SweetAlert function
export const userShowAlert = async (title, text, icon, confirmButtonText, cancelButtonText) => {
    const result = await Swal.fire({
        title,
        text,
        icon,
        confirmButtonText,
        cancelButtonText,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCancelButton: !!cancelButtonText, // Show the cancel button only if cancelButtonText is provided
        allowOutsideClick: false,
    });
    return result;
};

