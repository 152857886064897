import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Input() {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

 const staticPassword = 'sales@369';

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === staticPassword) {
      sessionStorage.setItem('isVerified', 'true'); 
      navigate('/home'); 
    } else {
     toast.error('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <form 
        onSubmit={handleSubmit} 
        className="flex flex-col w-80 p-6 bg-white border border-gray-300 rounded-lg shadow-md"
      >
        <input
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="p-3 mb-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button 
          type="submit" 
          className="p-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default Input;

