import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

const encryptUserData = (userdata) => {
   const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userdata), 'your-secret-key').toString();
   Cookies.set('user', encryptedData);
  };

const decryptUserData = () => {
  const encryptedData = Cookies.get('user');
  if (encryptedData) {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, 'your-secret-key');
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      if (decryptedData) {
        return JSON.parse(decryptedData);
      } else {
         return null;
      }
    } catch (error) {
       return null;
    }
  }
  return null;
};


export { encryptUserData, decryptUserData };
