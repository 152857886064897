import React, { useState, useEffect } from "react";
import PricingPlanCards from "./PricingPlanCards";
import { Data } from "../../utils/Data";
import { useLocation } from "react-router-dom";
import { PricingPlansApi } from "../../api/PricingPlansApi";
import { getAllSubscriptionRequest } from "../../redux/reducers/ducks/PricingPlansDuck"; // Assuming these are the correct paths
import { useDispatch } from "react-redux";

function PricingPlan() {
  const [activeTab, setActiveTab] = useState("Monthly"); // Active tab for filtering plans
  const [plans, setPlans] = useState({
    Monthly: [],
    Annually: [],
  }); // Initialize plans as an object
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const location = useLocation();

  // Define routes where specific styling is applied
  const noAbsolutePaths = ["/pricing", "/contact", "/videosection"];
  const isNoAbsolutePath = noAbsolutePaths.includes(location.pathname);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllSubscriptionRequest());
}, [dispatch]); // Could be unintentionally fired multiple times

  // Fetch pricing plans on component mount
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await PricingPlansApi(); // Call the API to fetch plans
        if (response && response.data) {
          // Group plans by their type (Monthly or Annually)
          const groupedPlans = response.data.reduce((acc, plan) => {
            const interval = plan.recurring?.interval; // Get the recurring interval (monthly or yearly)

            if (interval === "month") {
              if (!acc.Monthly) acc.Monthly = []; // Initialize Monthly array if not present
              acc.Monthly.push(plan); // Push plan to Monthly
            } else if (interval === "year") {
              if (!acc.Annually) acc.Annually = []; // Initialize Annually array if not present
              acc.Annually.push(plan); // Push plan to Annually
            }
            return acc;
          }, {});

          setPlans(groupedPlans); // Update state with grouped plans
        } else {
          setError("No plans found");
        }
      } catch (err) {
        setError(err.message || "Failed to load plans");
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const colorSchemes = [
    {
      colorClass: "bg-white",
      headerColor: "bg-[#FFF2FB]",
      barColor: "bg-[#fbe0f3]",
    },
    {
      colorClass: "bg-white",
      headerColor: "bg-[#f2f3fa]",
      barColor: "bg-[#e2e6ff]",
    },
    {
      colorClass: "bg-white",
      headerColor: "bg-[#FEECEE]",
      barColor: "bg-[#fde4e7]",
    },
  ];
  return (
    <section
      className={`pricing-section ${isNoAbsolutePath ? "pt-10" : "pt-0"} flex`}
    >
      <div className="container-fluid mx-auto relative flex gap-14 flex-col max-[900px]:gap-5 w-full">
        <div>
          <h4 className="mb-2 text-left text-[#FF3E3A] relative max-[767px]:mb-1">
            Our Pricing
          </h4>
          <h2 className="font-bold text-left text-black text-[30px] pb-3">
            {Data[2].title}
          </h2>
          <span className="title-border mb-3 w-28 h-px bg-[#FF3E3A] block"></span>
          <div className="font-normal text-left text-[#6b688d] pb-2">
            {Data[2].desc}
          </div>
        </div>
        <div className="flex mt-6 gap-5 max-[1024px]:mt-5 max-[950px]:flex-wrap">
          <div className="w-full">
            <div className="relative">
              <ul className="relative font-semibold flex flex-wrap gap-1">
                {["Monthly", "Annually"].map((tab) => (
                  <li key={tab} className="z-30 pricing-list">
                    <button
                      className={`cursor-pointer font-semibold text-base pt-2 pb-2 ps-5 inline-block rounded-t-2xl pe-5 ${
                        activeTab === tab ? "bg-[#f2f3fa]" : ""
                      }`}
                      role="tab"
                      aria-selected={activeTab === tab}
                      onClick={() => handleTabChange(tab)}
                    >
                      <span>{tab}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="p-8 max-[650px]:p-4 bg-[#f2f3fa] rounded-2xl rounded-tl-none pricing-b-cover">
              {loading ? (
                <p className="text-center text-gray-500">Loading...</p>
              ) : error ? (
                <p className="text-center text-red-500">{error}</p>
              ) : plans[activeTab]?.length > 0 ? (
                <div className="flex gap-6 max-[650px]:gap-4 justify-between opacity-100 max-[1150px]:flex-wrap max-[950px]:justify-center">
                  {plans[activeTab].map((plan, index) => (
                    <PricingPlanCards
                      key={index}
                      plan={{
                        ...plan,
                        ...colorSchemes[index % colorSchemes.length],
                      }}
                    />
                  ))}
                </div>
              ) : (
                <p className="text-center text-gray-500">
                  No plans available for {activeTab}.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingPlan;
